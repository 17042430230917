import * as React from 'react';
import styled, { css } from 'styled-components';

const ArticleLead = styled.p.attrs((props) => ({
  style: {
    maxWidth: props.maxWidth || '100%',
  },
}))`
  font-size: 1.8rem;
  font-style: italic;
  margin: 0 0 30px;
  line-height: 140%;
  hyphens: none!important;
  text-align: left!important;

  ${(props) => props.customStyle && css`
    ${props.customStyle}
  `}

  @media only screen and (min-width: 640px) {
    font-size: 2rem;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 2.4rem;
  }
`;

const Lead = ({ desc, maxWidth }) => (
  <ArticleLead
    maxWidth={maxWidth}
    dangerouslySetInnerHTML={{ __html: desc }}
  />
);

export default Lead;
