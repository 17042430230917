/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const OurServices = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  list-style: none;
  padding: 0;

  @media only screen and (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  li {
    a {
      transition: background 150ms ease-in;
      /* background: #3a1034; */
      background: #541a4c;
      display: block;
      height: 376px;
      width: 100%;
      position: relative;

      &:hover,
      &:focus {
        background: none;
      }
    }

    figure {
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 10px;
    }

    span {
      display: block;
    }

    .services__heading {
      bottom: 0;
      left: 0;
      padding: 16px;
      position: absolute;
      z-index: 1;
    }

    .services__title {
      font-size: 2rem;
      margin: 0 0 8px;
    }

    .services__lead {
      color: #fff;
      font-size: 1.4rem;
      line-height: 140%;
    }
  }
`;

const IntroSection = () => {
  const data = useStaticQuery(graphql`
    {
      banknotes: file(relativePath: {regex: "/banknoty-getta/"}) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            width: 376
          )
        }
      }
      coins: file(relativePath: {regex: "/srebrne-monety-2-rp/"}) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            width: 376
          )
        }
      }
      medals: file(relativePath: {regex: "/medale-ordery-odznaczenia/"}) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            width: 376
          )
        }
      }
    }
  `);

  return (

    <section className="section-services">
      <h2>Nasze usługi</h2>

      <OurServices>
        <li>
          <Link to="/skup-starych-banknotow/">
            <figure>
              <GatsbyImage
                image={getImage(data.banknotes)}
                alt="2 banknoty Getta Łódź: 2 i 50 marek"
              />
            </figure>
            <span className="services__heading">
              <span className="services__title">Skup i&nbsp;wycena banknotów</span>
              <span className="services__lead">Chętnie skupujemy polskie i&nbsp;zagraniczne banknoty, banknoty PRL, banknoty kolekcjnerskie, wzory bankotów, banknoty w&nbsp;gradingu PMG.</span>
            </span>
          </Link>
        </li>
        <li>
          <Link to="/skup-monet-olsztyn/">
            <figure>
              <GatsbyImage
                image={getImage(data.coins)}
                alt="Zbiór srebrnych monet z okresu międzywojennego"
              />
            </figure>
            <span className="services__heading">
              <span className="services__title">Skup i wycena starych monet</span>
              <span className="services__lead">Bezpłatnie wyceniamy monety polskie i&nbsp;zagraniczne. Chętnie skupujemy monety z&nbsp;okresu międzywojennego lub starsze.</span>
            </span>
          </Link>
        </li>
        <li>
          <Link to="/skup-medali-orderow-odznaczen/">
            <figure>
              <GatsbyImage
                image={getImage(data.medals)}
                alt="Polskie odznaczenia wojskowe"
              />
            </figure>
            <span className="services__heading">
              <span className="services__title">Skup medali, orderów i&nbsp;odznaczeń</span>
              <span className="services__lead">Zajmujemy się wyceną medali, orderów i&nbsp;odznaczeń wojskowych. Skupujemy wszystkie rodzaje odznaczeń wojskowych z&nbsp;różnych okresów historycznych.</span>
            </span>
          </Link>
        </li>
      </OurServices>
    </section>
  );
};

export default IntroSection;
